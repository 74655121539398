export enum Navbar {
  USER_LINK = "USER_LINK",
  CATEGORY_LINK = "CATEGORY_LINK",
  BUDGET_LINK = "BUDGET_LINK",
  AUTHENTICATION_LINK = "AUTHENTICATION_LINK",
}

export const taxable = [
  { value: false, label: "Vat Exempt" },
  { value: true, label: "Vat Inclusive" },
]
