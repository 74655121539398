import { Middleware } from "redux";
import { RootState } from "../interface/LoginInterface";

const PUBLIC_ROUTES = ["/", "/forgot-password"];

export const authMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    const result = next(action);
    const state = store.getState();

    // Check if we're not on a public route
    if (
      !PUBLIC_ROUTES.some((route) => window.location.pathname.startsWith(route))
    ) {
      if (!state.auth.accessToken) {
        // Only redirect if we're not already on the login page
        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
      } else {
      }
    } else {
    }

    return result;
  };
