import React from "react";
import Sidebar from "../includes/Sidebar";
import Header from "../includes/Header";

interface DashboardLayoutProps {
  children: React.ReactNode;
  isCollapsed: boolean;
  isMobile: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  isCollapsed,
  isMobile,
  setIsCollapsed,
}) => {
  return (
    <div className="flex h-screen bg-tertiary">
      <Sidebar
        isCollapsed={isCollapsed}
        isMobile={isMobile}
        setIsCollapsed={setIsCollapsed}
      />
      <div
        className={`flex-1 transition-all duration-300 ${
          (isCollapsed && isMobile) || isMobile ? "ml-24" : "ml-64"
        }`}
      >
        <Header />
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
