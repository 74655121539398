import { useNavigate } from "react-router-dom";
import { LogOutApiCall } from "../apiRequest/LoginRequest";
import { Logger } from "./Logger";
import { useAppDispatch } from "../redux/hooks";

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logout = async () => {
    try {
      // Make the API call
      const response = await LogOutApiCall(dispatch);

      // Check if the logout was successful
      if (response?.result?.success) {
        // Navigate to the login page
        localStorage.clear();
        navigate("/");
      } else {
        // Handle unsuccessful logout
        Logger.error("Logout was not successful:", response);
        // You might want to show an error message to the user here
      }
    } catch (error) {
      // Handle any errors that occur during the logout process
      Logger.error("Error during logout:", error);
      // You might want to show an error message to the user here
    }
  };

  return logout;
};
