import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaTachometerAlt,
  FaUsers,
  FaCog,
  FaBars,
  FaChevronDown,
  FaChevronRight,
  FaList,
  FaPlus,
  FaTh,
  FaMoneyBillAlt,
  FaLock,
  FaCubes,
  FaUnlockAlt,
  FaUserLock,
  FaDiceD6,
} from "react-icons/fa";
import { Navbar } from "../utils/Constant";
import { useSelector } from "react-redux";
import { RootState } from "../interface/LoginInterface";
import { LoadingModal } from "../utils/SpinnerLoader";
import LazyImage from "../utils/LazyImage";

interface SidebarProps {
  isCollapsed: boolean;
  isMobile: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<SidebarProps> = ({
  isCollapsed,
  isMobile,
  setIsCollapsed,
}) => {
  const location = useLocation();
  const { isLoggingOut, user } = useSelector((state: RootState) => state.auth);
  const [isUsersDropdownOpen, setUsersDropdownOpen] = useState(false);
  const [isCategoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [isBudgetDropdownOpen, setBudgetDropdownOpen] = useState(false);
  const [isAuthDropdownOpen, setAuthDropdownOpen] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const collapseDropdown = (navbar: string) => {
    setUsersDropdownOpen(false);
    setCategoryDropdownOpen(false);
    setBudgetDropdownOpen(false);
    setAuthDropdownOpen(false);
    switch (navbar) {
      case Navbar.USER_LINK:
        setUsersDropdownOpen(!isUsersDropdownOpen);
        break;
      case Navbar.CATEGORY_LINK:
        setCategoryDropdownOpen(!isCategoryDropdownOpen);
        break;
      case Navbar.BUDGET_LINK:
        setBudgetDropdownOpen(!isBudgetDropdownOpen);
        break;
      case Navbar.AUTHENTICATION_LINK:
        setAuthDropdownOpen(!isAuthDropdownOpen);
        break;
    }
  };

  const mobileCaret = isCollapsed || isMobile ? "hidden" : "ml-2";
  const mobileInline = isCollapsed || isMobile ? "hidden" : "inline";
  const mobileWidth = isCollapsed || isMobile ? "w-100" : "w-full";
  const hamburgerBtn = isCollapsed || isMobile ? "inline" : "hidden";

  const linkClasses = (path: string) =>
    `block py-2.5 px-4 rounded transition duration-200 hover:bg-secondary flex items-center ${
      location.pathname === path ? "bg-secondary" : ""
    } ${mobileWidth}`;

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-primary text-white transition-all duration-300 ease-in-out ${
        isCollapsed || isMobile ? "w-16 md:w-16" : "w-64"
      } md:w-64`}
    >
      <LoadingModal isOpen={isLoggingOut || false} />
      <div className="p-4 text-xl font-bold flex justify-between items-center">
        <span
          className={`transition-all duration-300 cursor-pointer ${
            isCollapsed || isMobile ? "hidden" : "block"
          }`}
          onClick={toggleSidebar}
        >
          <LazyImage
            src="https://jasmsolution.sgp1.digitaloceanspaces.com/logo/jms-dashboard-logo"
            alt="JMS Logo"
            className="object-contain"
          />
        </span>
        <button className={`${hamburgerBtn}`} onClick={toggleSidebar}>
          <FaBars size={24} />
        </button>
      </div>
      <nav
        className={`flex-1 flex flex-col font-poppins ${
          isCollapsed || isMobile
            ? "md:items-center items-center"
            : "md:items-start items-start"
        } px-4`}
      >
        <Link to="/admin" className={linkClasses("/admin")}>
          <FaTachometerAlt className="mr-2" />
          <span className={`transition-all duration-300 ${mobileInline}`}>
            Dashboard
          </span>
        </Link>

        {/* Category Menu */}
        <Link to="/admin/categories" className={linkClasses("/admin/categories")}>
          <FaTh className="mr-2" />
          <span className={`transition-all duration-300 ${mobileInline}`}>
            Categories
          </span>
        </Link>

        {/* Budget Menu */}
        {/* <div className={`${mobileWidth}`}>
          <button
            onClick={() => collapseDropdown(Navbar.BUDGET_LINK)}
            className="w-full flex items-center justify-between py-2.5 px-4 rounded transition duration-200 hover:bg-secondary"
          >
            <div className="flex items-center">
              <FaMoneyBillAlt className="mr-2" />
              <span className={`transition-all duration-300 ${mobileInline}`}>
                Budget
              </span>
            </div>
            {isBudgetDropdownOpen ? (
              <FaChevronDown className={`${mobileCaret}`} />
            ) : (
              <FaChevronRight className={`${mobileCaret}`} />
            )}
          </button>
          {isBudgetDropdownOpen && (
            <div className={`${isCollapsed || isMobile ? "ml-2" : "pl-8"}`}>
              <Link
                to="/admin/budget/expenses"
                className={linkClasses("/admin/budget/expenses")}
              >
                <FaList className="mr-2" />
                <span className={`transition-all duration-300 ${mobileInline}`}>
                  Expense
                </span>
              </Link>
              {user?.roles.includes("sales") && (
                <Link
                  to="/admin/budget/request"
                  className={linkClasses("/admin/budget/request")}
                >
                  <FaPlus className="mr-2" />
                  <span
                    className={`transition-all duration-300 ${mobileInline}`}
                  >
                    Request
                  </span>
                </Link>
              )}
            </div>
          )}
        </div> */}

        {/* User Menu */}
        {/* <div className={`${mobileWidth}`}>
          <button
            onClick={() => collapseDropdown(Navbar.USER_LINK)}
            className="w-full flex items-center justify-between py-2.5 px-4 rounded transition duration-200 hover:bg-secondary"
          >
            <div className="flex items-center">
              <FaUsers className="mr-2" />
              <span className={`transition-all duration-300 ${mobileInline}`}>
                Users
              </span>
            </div>
            {isUsersDropdownOpen ? (
              <FaChevronDown className={`${mobileCaret}`} />
            ) : (
              <FaChevronRight className={`${mobileCaret}`} />
            )}
          </button>
          {isUsersDropdownOpen && (
            <div className={`${isCollapsed || isMobile ? "ml-2" : "pl-8"}`}>
              <Link to="/admin/users" className={linkClasses("/admin/users")}>
                <FaList className="mr-2" />
                <span className={`transition-all duration-300 ${mobileInline}`}>
                  List
                </span>
              </Link>
            </div>
          )}
        </div> */}

        <Link to="/admin/vendor" className={linkClasses("/admin/vendor")}>
          <FaDiceD6 className="mr-2" />
          <span className={`transition-all duration-300 ${mobileInline}`}>
            Vendors
          </span>
        </Link>

        <Link to="/admin/expenses" className={linkClasses("/admin/expenses")}>
          <FaList className="mr-2" />
          <span className={`transition-all duration-300 ${mobileInline}`}>
            Expense
          </span>
        </Link>

        {/* Settings Menu */}
        {/* <Link
          to="/admin/departments"
          className={linkClasses("/admin/departments")}
        >
          <FaCubes className="mr-2" />
          <span className={`transition-all duration-300 ${mobileInline}`}>
            Departments
          </span>
        </Link> */}

        {/* Aunthentication Menu */}
        {(user?.roles.includes("admin") || user?.roles.includes("owner")) && (
          <div className={`${mobileWidth}`}>
            <button
              onClick={() => collapseDropdown(Navbar.AUTHENTICATION_LINK)}
              className="w-full flex items-center justify-between py-2.5 px-4 rounded transition duration-200 hover:bg-secondary"
            >
              <div className="flex items-center">
                <FaLock className="mr-2" />
                <span className={`transition-all duration-300 ${mobileInline}`}>
                  Authentication
                </span>
              </div>
              {isAuthDropdownOpen ? (
                <FaChevronDown className={`${mobileCaret}`} />
              ) : (
                <FaChevronRight className={`${mobileCaret}`} />
              )}
            </button>
            {isAuthDropdownOpen && (
              <div className={`${isCollapsed || isMobile ? "ml-2" : "pl-8"}`}>
                <Link
                  to="/admin/authentication/users"
                  className={linkClasses("/admin/authentication/users")}
                >
                  <FaUsers className="mr-2" />
                  <span
                    className={`transition-all duration-300 ${mobileInline}`}
                  >
                    Users
                  </span>
                </Link>
                <Link
                  to="/admin/authentication/roles"
                  className={linkClasses("/admin/authentication/roles")}
                >
                  <FaUserLock className="mr-2" />
                  <span
                    className={`transition-all duration-300 ${mobileInline}`}
                  >
                    Roles
                  </span>
                </Link>
                <Link
                  to="/admin/authentication/permissions"
                  className={linkClasses("/admin/authentication/permissions")}
                >
                  <FaUnlockAlt className="mr-2" />
                  <span
                    className={`transition-all duration-300 ${mobileInline}`}
                  >
                    Permission
                  </span>
                </Link>
              </div>
            )}
          </div>
        )}

        {/* Settings Menu */}
        <Link to="/admin/settings" className={linkClasses("/admin/settings")}>
          <FaCog className="mr-2" />
          <span className={`transition-all duration-300 ${mobileInline}`}>
            Settings
          </span>
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;
