import React, { useState, useEffect, useRef } from "react";
import { FaUser, FaSignOutAlt, FaCaretDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLogout } from "../utils/Logout";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slice";

const Header: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const logout = useLogout();
  const user = useSelector(selectUser);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    logout();
    setIsDropdownOpen(false);
  };

  return (
    <header className="flex flex-col w-full p-4 bg-transparent">
      <div className="flex justify-between items-center mb-2">
        <div className="items-center space-x-4 lg:flex hidden font-poppins">
          <span className="text-base font-bold">
            Hello {user?.details.fname || "User"}
          </span>
          <span className="text-gray-500 flex items-center text-xs">
            <span className="mr-2">»</span>
            {new Date().toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </span>
        </div>
        <div className="flex items-center space-x-4 relative">
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={toggleDropdown}
              className="flex items-center space-x-2 focus:outline-none"
            >
              <img
                src={
                  user?.details.profile_img || "https://via.placeholder.com/30"
                }
                alt={user?.details.fname || "User"}
                className="w-8 h-8 rounded-full"
              />
              <span className="text-sm font-medium hidden md:inline font-poppins">
                {user?.details.fname || "User"}
              </span>
              <FaCaretDown className="text-sm font-medium hidden md:inline" />
            </button>
            {isDropdownOpen && (
              <div className="text-sm absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-20">
                <Link
                  to="/admin/profile"
                  className="block px-4 py-2 font-poppins text-gray-800 hover:bg-gray-100 flex items-center"
                >
                  <FaUser className="mr-2" /> Profile
                </Link>
                <hr className="border-gray-300 w-full mt-1.5" />
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 font-poppins text-gray-800 hover:bg-gray-100 flex items-center"
                >
                  <FaSignOutAlt className="mr-2" /> Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="border-gray-300 w-full mt-1.5" />
    </header>
  );
};

export default Header;
