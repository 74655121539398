import { Logger } from "../utils/Logger";
import { setUser, clearAll } from "../redux/slice";
import api, { setNewAccessToken } from "./CoreRequest";
import { ApiResponse } from "../interface/ApiInterface";
import { getNavigationSettingsList } from "./NavigationRequest";
import { setReloadList, resetBooleanState } from "../redux/slice";

export const LoginApiCall = async (dispatch?: any, data?: any) => {
  try {
    const response = await api.post<ApiResponse>("/api/auth/login", data);
    Logger.info("Authenticated .", response);
    setNewAccessToken(response.result?.data?.access_token);
    dispatch(resetBooleanState());
    delete response.result?.data?.access_token;
    delete response.result?.data?.token_type;
    delete response.result?.data?.expires_in;
    dispatch(setUser(response.result?.data));
    await getNavigationSettingsList(dispatch);
    return response;
  } catch (err: any) {
    Logger.error("Error Authenticated: ", err);
    if (err?.response?.status === 401) {
      await LogOutApiCall();
    }
    throw err;
  }
};

export const LogOutApiCall = async (dispatch?: any) => {
  try {
    dispatch(setReloadList({ key: "isLoggingOut", value: true }));
    const res = await api.post("/api/auth/logout");
    dispatch(clearAll());
    localStorage.clear();
    return res.data;
  } catch (err: any) {
    dispatch(clearAll());
    localStorage.clear();
    window.location.href = "/";
    throw err;
  }
};
