import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Logger } from "../utils/Logger";
import { setAccessToken, clearToken } from "../redux/slice";
import { store } from "../redux/store";
import { getSubdomain } from "../utils/Helpers";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";
const APP_VERSION = process.env.REACT_APP_VER || "1.0.0";
const JMS_PREFIX = getSubdomain() || "wlinx";

// Create a base axios instance
const createAxiosInstance = (contentType: string): AxiosInstance => {
  return axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": contentType,
      "App-Version": APP_VERSION,
      "X-Prefix-Schema": JMS_PREFIX,
    },
  });
};

const jsonInstance = createAxiosInstance("application/json");
const formDataInstance = createAxiosInstance("multipart/form-data");

// Function to update the access token in both axios instances
export const updateAxiosToken = (token: string | null) => {
  if (token) {
    jsonInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    formDataInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  } else {
    delete jsonInstance.defaults.headers.common["Authorization"];
    delete formDataInstance.defaults.headers.common["Authorization"];
  }
};

// Initialize the axios instances with the current token from Redux store
updateAxiosToken(store.getState().auth.accessToken);

// Subscribe to store changes to update the token when it changes in Redux
store.subscribe(() => {
  const token = store.getState().auth.accessToken;
  updateAxiosToken(token);
});

export const setNewAccessToken = (token?: string) => {
  if (token) {
    Logger.info("Clearing authorization");
    store.dispatch(clearToken());
    Logger.info("Setting new authorization: ", token);
    store.dispatch(setAccessToken(token));
    updateAxiosToken(token);
  }
};

// Helper function to determine which instance to use based on the data
const getAxiosInstance = (data: any): AxiosInstance => {
  return data instanceof FormData ? formDataInstance : jsonInstance;
};

// Custom axios function that chooses the appropriate instance and extracts the data from the response
const axiosRequest = async <T = any,>(
  config: AxiosRequestConfig
): Promise<T> => {
  const instance = getAxiosInstance(config.data);
  const response: AxiosResponse<T> = await instance.request<T>(config);
  return response.data;
};

export default {
  get: <T = any,>(url: string, config?: AxiosRequestConfig) =>
    axiosRequest<T>({ ...config, method: "get", url }),
  post: <T = any,>(url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosRequest<T>({ ...config, method: "post", url, data }),
  put: <T = any,>(url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosRequest<T>({ ...config, method: "put", url, data }),
  delete: <T = any,>(url: string, config?: AxiosRequestConfig) =>
    axiosRequest<T>({ ...config, method: "delete", url }),
  patch: <T = any,>(url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosRequest<T>({ ...config, method: "patch", url, data }),
};
